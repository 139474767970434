import React from 'react';
import './About.scss';
import '../utilities/utils.scss'

import pic1 from './photos/1.jpg';
import pic2 from './photos/2.jpg';
import pic3 from './photos/3.jpg';
import pic4 from './photos/4.jpg';
import pic5 from './photos/5.jpg';
import pic6 from './photos/6.jpg';
import pic7 from './photos/7.jpg';
import pic9 from './photos/9.jpg';
import pic12 from './photos/12.JPG';
import pic13 from './photos/13.JPG';
import pic14 from './photos/14.jpg';
import Me from '../images/mee.png';
import Twitter from '../images/twitter.svg';
import LinkedIn from '../images/li.svg';
import Mail from '../images/mail.svg';
import arrow from '../images/arrow-up-right.svg';
import Marquee from "react-fast-marquee";
import { Fade } from "react-awesome-reveal";


const cardDetails = {
    0: {
        imgUrl: pic1,
        title: "Text 1"
    },

    1: {
        imgUrl: pic2,
        title: "Text 2"
    },

    2: {
        imgUrl: pic4,
        title: "Text 4"
    },

    3: {
        imgUrl: pic7,
        title: "Text 7",
    },

    4: {
        imgUrl: pic5,
        title: "Text 5"
    },

    5: {
        imgUrl: pic6,
        title: "Text 6"
    },

    6: {
        imgUrl: pic3,
        title: "Text 7",
    },
    7: {
        imgUrl: Me,
        title: "Text 7",
    },
    8: {
        imgUrl: pic12,
        title: "Text 7",
    },
    9: {
        imgUrl: pic13,
        title: "Text 7",
    },
    10: {
        imgUrl: pic14,
        title: "Text 7",
    },
}

const EDUCATION = [
    {   
        date: '2023',
        top:'Master of Information',
        botttom: 'University of Toronto',
        extra: 'User experience design— 4.0 GPA'
    },
    {
        date: '2018',
        top: 'Honours Bachelor of Science',
        botttom: 'University of Toronto',
        extra: 'Major: Neuroscience; minor: Computer Science, Statistics'
    }
]
const ARTICLES_LIST = [
    {
        title: "Immigrant Families' Health- Related Information Behavior on Instant Messaging Platforms",
        headline: 'CHI',
        date: '2021',
        desc: 'For immigrant families, instant messaging family groups are a common platform for sharing and discussing health-related information. Immigrants often maint...',
        link: 'https://dl.acm.org/doi/10.1145/3411763.3451751',
        class: 'figma',
    },

    {
        title: "A Wish Design System Color Story",
        headline: 'Medium',
        date: '2021',
        desc: 'During the winter of 2021, I had the chance to be a Product Design intern at Wish. I remember it being the first day of my internship and I was...',
        link: 'https://bootcamp.uxdesign.cc/a-wish-design-system-color-story-ef2c38de97e',
    },
    {
        title: "UX Design Projects: What do I do next, and which methodology do I use?",
        headline: 'Medium',
        date: '2020',
        desc: 'You’ve started your UX project, or maybe you were given a problem to tackle. Perhaps you’ve done the research, or maybe you’re not sure where to even begin.',
        link: 'https://uxdesign.cc/figma-design-thinking-building-a-design-system-for-an-existing-product-388bfdeb844b',
    },
    {
        title: "Figma & design thinking: Building a design system for an existing product",
        headline: 'Medium',
        date: '2020',
        desc: 'When I joined Potluck as a UX Design intern, we were using Sketch, Craft, and InVision for our design-to-development pipeline.',
        link: 'https://uxdesign.cc/figma-design-thinking-building-a-design-system-for-an-existing-product-388bfdeb844b',
    },
    {
        title: "Losing a Google UX Design Internship to COVID and What I Did About it",
        headline: 'Medium',
        date: '2020',
        desc: 'I remember when I applied for the internship. I kept refreshing the page until the application button went live. It almost felt like if I took my eyes off of it, I would...',
        link: 'https://uxdesign.cc/figma-design-thinking-building-a-design-system-for-an-existing-product-388bfdeb844b',
    },
    {
        title: "Neumorphism, visual accessibility, and empathy",
        headline: 'Medium',
        date: '2020',
        desc: "'A good UX designer has empathy.'This is something every UX designer has heard at some point in their career. Empathy helps us get into the mindset of the user and build...",
        link: 'https://uxdesign.cc/neumorphism-visual-accessibility-and-empathy-d1c5ed2a1f03',
    }, {
        title: "Expectations vs Realities: UX design in the industry vs UX design at school",
        headline: 'Medium',
        date: '2019',
        desc: "If you’ve been to design school or taken a design course or even researched UX design, you’ll know how important the design process is. Having done design classes...",
        link: 'https://uxdesign.cc/expectations-vs-realities-ux-design-in-the-industry-vs-ux-design-at-school-51851e59a5eb',
    },

]

function About() {
    return (
        <div className="about"> 
            <Fade triggerOnce direction='up' className='image-mb'>
                <AutoplayCarousel />
            </Fade>
            <div className='about-container'>
                <div className='col-12-grid'>
                    <div className='span-4 about-big'>
                        <Fade triggerOnce direction='up' className='about-pic-cont image-web'>
                            <div className='about-me' style={{ backgroundImage: `url('${pic9}')` }}>

                            </div>

                        </Fade>

                    </div>

                    <div className='span-8 about-small'>
                        <Fade triggerOnce direction='up' delay={100}>
                            <div>
                        <div className='about-big'>
                        <h1>Hello,</h1>
                        <h1 className='title-2'>I'm Taamannae</h1>
                        <p>/tah · maan · nuh/</p>
                        <p>I'm a product designer who thrives on all things ambigious and gnarly. With a strong focus on craft, storytelling, and high exploration output, I enjoy diving into the details, solving problems thoughtfully, and bringing order to chaos.  <br />
                        </p>

                        </div>


                        <div className='col-2-grid'>
                            <div>
                            <h3>Day job</h3>
                                <p>Currently, I am a <b>Senior Product Designer at Figma</b>. Before that, I co-founded Octoshop, designed at Meta, Xbox, and Wish, and freelanced for startups, VC firms, and  agencies. I hold an HBSc in Neuroscience, Computer Science, and Statistics, along with an MI from the University of Toronto.

                            </p>
                            </div>
                            <div>
                                <h3>Out of office</h3>

                                <p>
                                When I'm not building things, I love traveling, playing video games, baking, cooking, painting, and capturing moments through photography. I'm a natural puzzler with a passion for trivia and crosswords—maybe even to a concerning degree! If I've piqued your interest, feel free to reach out!
                            </p>
                            </div>

  
                        </div>

                                <div className='flex socials'>
                                    <a className="gooey" href="https://x.com/taamannae" rel="noreferrer" target='_blank'><img src={Twitter} alt="Twitter" /></a>
                                    <a className="gooey" href="https://www.linkedin.com/in/taamannae/" rel="noreferrer" target='_blank'><img src={LinkedIn} alt="LinkedIn" /></a>
                                    <a className="gooey" href="mailto:taamannae@gmail.com" rel="noreferrer" target='_blank'><img src={Mail} alt="Email" /></a>
                                </div>


                        </div>
                        <div>
                        </div>
                        </Fade>

                    </div>
  
                </div>
            </div>

            <div className='experience root-container col-12-grid'>
                <div className='span-4 mt-64'>
                    <h1>My super powers</h1>

                </div>
                <div className='span-8 powers col-2-grid'>
                    <div>
                        <h3>Structure in ambiguity</h3>
                        <p>I have a knack for adding clarity and structure to ambiguous problems and environments. I love making complex situations easier for others to navigate and understand.</p>
                    </div>
                    <div>
                        <h3>Storytelling</h3>
                        <p>I love taking complicated concepts and crafting easy to understand narratives through animations and visuals on slide decks. </p>
                    </div>
                    <div>
                        <h3>Design speed</h3>
                        <p>I have optimimized my workflows and tools to focus on generating ideas and exploring a larger volume of options in a shorter amount of time, allowing me to explore solutions thoroughly and deeply.</p>
                    </div>
                    <div>
                        <h3>Design 🤝 development</h3>
                        <p>As a trained engineer, I understand software systems and limitations. I can also get in the trenches with my devs. This means anything from fixing small bugs in production to code reviews in Gitbhub.</p>
                    </div>
                </div>
            </div>

            <AutoplayCarousel class="image-web"/>

            <div className='experience root-container featured col-12-grid mt-64'>
                <div className='span-4'>
                    <h1>Featured in</h1>

                </div>
                <div className='span-8 col-8-grid'>
                    <div className='span-2'><h3>2024</h3></div>
                    <div className='span-6 info'>
                        <h3><a href="https://www.figma.com/blog/breaking-in-a-guide-to-landing-your-first-product-design-role/" rel="noreferrer" >Breaking in: A guide to landing your first product design role <span><img src={arrow} alt="External" /></span></a></h3>
                        <p>Shortcut · Article <span className='mb-only'>· 2024</span></p>
                    </div>
                    <div className='span-2'><h3>2023</h3></div>
                    <div className='span-6 info'>
                        <h3><a href="https://theygotacquired.com/software/octoshop-acquired-by-ibotta/" rel="noreferrer" >OctoShop acquired by Ibotta <span><img src={arrow} alt="External" /></span></a></h3>
                        <p>They got aquired · Article <span className='mb-only'>· 2023</span></p>
                    </div>
                    <div className='span-2'><h3>2023</h3></div>

                    <div className='span-6 info'>
                        <h3><a href="https://www.youtube.com/watch?v=jJfkBcYaRF4" rel="noreferrer" >Portfolio 101 <span><img src={arrow} alt="External" /></span></a></h3>
                        <p>Figma · Talk <span className='mb-only'>· 2023</span></p>
                    </div>
                    <div className='span-2'><h3>2023</h3></div>

                    <div className='span-6 info'>
                        <h3><a href="https://www.jsums.edu/art/2023/01/27/ux-design-chats/" rel="noreferrer" >UX Design chat <span><img src={arrow} alt="External" /></span></a></h3>
                        <p>Jackson State University · Talk <span className='mb-only'>· 2023</span></p>
                    </div>
                    <div className='span-2'><h3>2023</h3></div>

                    <div className='span-6 info'>
                        <h3><a href="https://open.spotify.com/episode/7GRzCnB4ODMaqoXsA5cZ3L?si=283ff4c6b44d4d91" rel="noreferrer" >Breaking into UX Design and Applying to Internships 101 <span><img src={arrow} alt="External" /></span></a></h3>
                        <p>UX Lounge · Podcast <span className='mb-only'>· 2023</span></p>
                    </div>
                    <div className='span-2'><h3>2022</h3></div>

                    <div className='span-6 info'>
                        <h3><a href="https://www.coursera.org/articles/ux-designer-portfolio-beginners-guide" rel="noreferrer" >7 UX Designer Portfolio Examples: A Beginner's Guide <span><img src={arrow} alt="External" /></span></a></h3>
                        <p>Coursera · Article <span className='mb-only'>· 2022</span></p>
                    </div>
                    <div className='span-2'><h3>2022</h3></div>

                    <div className='span-6 info'>
                        <h3><a href="https://bootcamp.uxdesign.cc/three-product-designers-break-down-their-portfolio-b8b716db3216" rel="noreferrer" >Three product designers break down their portfolio <span><img src={arrow} alt="External" /></span></a></h3>
                        <p>Bootcamp · Article <span className='mb-only'>· 2022</span></p>
                    </div>
                    <div className='span-2'><h3>2021</h3></div>

                    <div className='span-6 info'>
                        <h3><a href="https://www.eleken.co/blog-posts/eighteen-design-resume-examples-and-writing-tips-to-get-hired" rel="noreferrer" >18 Design Resume Examples and Writing Tips To Get Hired <span><img src={arrow} alt="External" /></span></a></h3>
                        <p>Eleken · Article · 2021 <span className='mb-only'>· 2021</span></p>
                    </div>
                </div>
            </div>

            <div className='experience root-container col-12-grid mt-64'>
                <div className='span-4 exp-left'><h1>Experience</h1></div>
                <div className='span-8 exp-right mt-16'>
                    <div className='col-8-grid'>
                        <div className='span-2 date'><h3>2023—Now</h3></div>
                        <div className='span-6 info'>
                            <h3>Figma</h3>
                            <h3 className='mb-only-date'><span>Product designer</span> <span className='mb-only'>· 2023—Now</span></h3>
                            <p>Product designer on AI. </p>
                        </div>

                        <div className='span-2 date'><h3>2016—2023</h3></div>
                        <div className='span-6 info'>
                            <h3>Freelance</h3>
                            <h3 className='mb-only-date'><span>Product designer</span> <span className='mb-only'>· 2016—2023</span></h3>
                            <p>Led the design and implementation of interfaces, 0-1 design systems, landing pages and promotional material for various YC companies such as Brightdrop, Rally.video, Webapp.io, Datafold and VC firms such as Saltwater. </p>
                        </div>

                        <div className='span-2 date'><h3>2020—2021</h3></div>
                        <div className='span-6 info'>
                            <h3>Instok</h3>
                            <h3 className='mb-only-date'><span>Co-founder & Head of design</span> <span className='mb-only'>· 2020—2021</span></h3>

                            <p>Designed full branding and UX of a web extension to help users compare prices, get cashback from purchases, and set up alerts for out-of-stock items. Established and coded a Design System that saved engineering 30 hrs/month/dev. Acquired by Ibotta 2021. $300k ARR,  150k + downloads on Google Chrome, multi-million dollar acquisition</p>
                        </div>

                        <div className='span-2 date'><h3>2020—2021</h3></div>
                        <div className='span-6 info'>
                            <h3>Not Dev</h3>
                            <h3 className='mb-only-date'><span>Product designer</span> <span className='mb-only'>· 2020—2021</span></h3>
                            <p>Not Dev is a design agency that focuses on product, design systems, brand and illustration. I collaborated with other designers to create interfaces for clients such as Figleaf, Toyota, Docusign & Google.</p>
                        </div>

                        <div className='span-2 date'><h3>2018—2020</h3></div>
                        <div className='span-6 info'>
                            <h3>Parsehub</h3>
                            <h3 className='mb-only-date'><span>UX Designer & Software engineer</span> <span className='mb-only'>· 2018—2020</span></h3>
                            <p>Led design strategy of two products. Conducted user research, created personas, journey maps, wireframes, prototypes, visual designs, and conducted user tests. Reduced Parsehub’s customer support dependency for new subscriptions by. Developed and coded live versions of the products using ReactJS and AngularJS.</p>
                        </div>

                        <div className='span-2 date'><h3>2016—2017</h3></div>
                        <div className='span-6 info'>
                            <h3>IBM</h3>
                            <h3 className='mb-only-date'><span>Software engineer</span> <span className='mb-only'>· 2016—2017</span></h3>
                            <p>Designed and developed an application to make it easier for users to use Db2 through a GUI by using electron, ReactJS, NodeJS and docker. App received 1500+ downloads as of Aug 2017, 50% of downloads were by new users of Db2. Created story board and scripts for promotional 2D animations for Db2 v11 using Illustrator and After Effects.</p>
                        </div>

                    </div>

                </div>

            </div>

            <div className='experience root-container col-12-grid mt-64'>
                <div className='span-4 exp-left'><h1>Internships</h1>
                    <div className='span-4 info'>
                        <p>I completed these internships as part of the co-op portion of my masters degree.</p>
                    </div>
                
                </div>
                <div className='span-8 exp-right'>
                    <div className='col-8-grid'>
                        <div className='span-2 date'><h3>Sep—Dec ’23</h3></div>
                        <div className='span-6 info'>
                            <h3>Punchcut</h3>
                            <h3 className='mb-only-date'><span>Interaction design intern</span> <span className='mb-only'>· Sep—Dec ’23</span></h3>
                            <p>Designed interactions and workflows for a Fortune 100, large-screen entertainment company. Planned a user research project for a Fortune 100 streaming company. Designed, planned, and built a framework for designing for inclusion.</p>
                        </div>

                        <div className='span-2 date'><h3>May—Aug ’22</h3></div>
                        <div className='span-6 info'>
                            <h3>Meta</h3>
                            <h3 className='mb-only-date'><span>Product design intern</span> <span className='mb-only'>· May—Aug ’22</span></h3>
                            <p>Intern on Facebook web’s Product Foundations (Design Systems) team. Aligned with PMs, Engineers and designers on cross teams to design 2 core unification projects + side projects. Built a coded prototype with ReactJS and SASS. Fixed and shipped 5 UI bugs in code, updated 25 design system bugs in Figma. </p>
                        </div>

                        <div className='span-2 date'><h3>Jun—Sep ’21</h3></div>
                        <div className='span-6 info'>
                            <h3>Microsoft</h3>
                            <h3 className='mb-only-date'><span>UX design intern</span> <span className='mb-only'>· Jun—Sep ’21</span></h3>
                            <p>Spearheaded exploratory northstar work for improving diversity & inclusion of Xbox’s suite of products for low income women. Audited existing gaps in Xbox’s diversity and defined 3 solution areas for future exploration</p>
                        </div>

                        <div className='span-2 date'><h3>Jan—Apr ’21</h3></div>
                        <div className='span-6 info'>
                            <h3>Wish</h3>
                            <h3 className='mb-only-date'><span>Product design intern</span> <span className='mb-only'>· Jan—Apr ’21</span></h3>
                            <p>Owned high impact projects on 3 teams. Collaborated with PMs, engineers, lawyers and logistics team for policy designs. Redefined the color tokens in the design system, received buy-in from stakeholders and converted colors to code for developers. Delivered designs for product compliance workflows through an iterative process as the sole designer.</p>
                        </div>
                    </div>
                </div>
            </div>


            <div className='experience root-container col-12-grid mt-64'>
                <div className='span-4 exp-left'><h1>Writing</h1></div>
                <div className='span-8 exp-right'>
                    <div className='col-8-grid'>
                        {renderCards()}

                    </div>

                </div>

            </div>

            <div className='experience root-container col-12-grid'>
                <div className='span-4 exp-left'>
                    <h1>Education</h1>

                </div>
                <div className='span-8 col-8-grid exp-right'>
                    {renderWork(EDUCATION)}

                </div>
            </div>


        </div>
    );
}


function AutoplayCarousel(props) {
    return (
        <Marquee className={`marquee ${props.class}`} speed={100}>
        {Object.keys(cardDetails).map((detailKey) => {
            return (
            <img alt=" " src={cardDetails[detailKey].imgUrl}/>

            );
        })}
        </Marquee>
    );
}

function renderWork(list, delay) {
    return(
        list.map((item, i) => (
                <>
                    <div className='span-2 date'>
                        <h3>{item.date}</h3>
                        </div>
                    <div className='span-6 info'>
                        <div>
                        <h3>{item.top}</h3>
                        <h3>{item.botttom}</h3>
                        <p>{item.extra}</p>
                        </div>
                    </div>
                </>
            )
        )
    )
}

function renderCards() {
    return (
        ARTICLES_LIST.map((item, i) => {

            return (
                <>
                    <div className='span-2 date'>
                        <h3>{item.date}</h3>
                    </div>

                    <div className='span-6 info'>
                        <div>
                            <h3><a href={item.link}>{item.title}<span><img src={arrow} alt="External" /></span></a></h3>
                            <p>{item.desc}</p>
                        </div>
                    </div>
                </>
            );

        })
    )

}

export default About;